<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
      <div slot="content-buttons">
        <Button _key="btnTeamCreateUpdate" title="Equipe" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
      <div slot="content-buttons-table-header">
        <Button _key="btnGenerateFinance" :title="'Lançar no Financeiro ' + selected.length" type="primary" size="small"
          :clicked="confirmGenerate" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Equipe" :width="850" :height="250" v-show="showModal('teamCreateUpdate')">
      <TeamCreateUpdate :rent="rent" />
    </Modal>
    <Modal title="Tem certeza que deseja lançar?" :width="500" v-show="showModal('generateFinance')">
      <Alert type="warning">
        <span>
          Atenção, certifique-se que está lançando o valor correto, após lançado
          somente o Financeiro poderá alterar
        </span>
      </Alert>
      <Confirmation :isModal="false" title="Você tem certeza?" type="primary"
        :confirmed="createPaymentsAccountsReceivable" />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";

import TeamCreateUpdate from './TeamCreateUpdate.vue'

import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "PeriodRentList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    Button, TeamCreateUpdate, Alert, Confirmation
  },
  props: {
    rent: Object,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/human-resources/team/get-all",
        urlDeleteAllApi: "/api/v1/human-resources/team/delete",
        urlGenerateAllApi: "/api/v1/human-resources/team/create-accounts-payments",
        headerTable: [
          {
            field: "photo",
            container: "nixloc-photo-collaborator",
            type: "image",
            classCssBody: "img-user",
          },
          {
            field: "fullName",
            title: "Nome",
            type: "link",
            eventName: "teamUpdate",
            classCssBody: "center-vertical",
          },
          {
            field: "fullPeriod",
            title: "Período",
            type: "text",
            classCssBody: "center-vertical",
          },
          {
            field: "description",
            title: "Descrição",
            type: "html",
            classCssBody: "center-vertical",
          },
          {
            field: "dueDate",
            title: "Vencimento",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "value",
            title: "Valor/Cachê",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            title: "Financeiro",
            field: "status",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
        ],
      },
      propsParam: {
        any: this.rent.id,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event", "showModal"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "addEvent", "removeLoading"]),
    ...mapActions("generic", ["putAllApi"]),
    create() {
      this.addEvent({ name: "teamCreateUpdate" });
      this.openModal('teamCreateUpdate');
      this.removeLoading(["btnTeamCreateUpdate"]);
    },
    confirmGenerate() {
      this.removeLoading(["btnGenerateFinance"]);
      this.openModal("generateFinance");
    },
    createPaymentsAccountsReceivable() {
      let params = {
        url: this.templateList.urlGenerateAllApi,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "teamUpdate") this.openModal("teamCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
<style>
.box-finance {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 8px;
}

.finance-delay {
  background-color: red;
}

.finance-paid {
  background-color: #009183;
}

.finance-today {
  background-color: #ff8a1b;
}

.finance-scheduled {
  background-color: #3d4eae;
}
</style>