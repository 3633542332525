<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnRentCreate" title="Locação" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newRent" />
    <DropdownOptionsRent v-if="id" :rent="rent" :statusMovimentStock="statusMovimentStock"
      :totalProductForInvoice="totalProductForInvoice" />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge :title="'Nº' + rent.number.toString()" type="primary" size="medium" />
          <Badge :title="statusRental.title" :type="statusRental.type" size="small" />
        </b-col>
      </b-row>
      <br />
    </div>
    <RentTotalization v-if="id" :statusRental="rent.statusRental" :rentId="id" />
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <InputText title="Identificação" field="name" :formName="formName" :required="false" :maxLength="100"
            v-model="rent.identification" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Tipo" field="type" :formName="formName" :required="false"
            url="/api/v1/rental/type-rent/select-all" v-model="rent.typeRent" nameNewRegister="typeRentalCreateUpdate"
            titleNewRegister="Tipo" :widthNewRegister="500" :heightNewRegister="250">
            <TypeRentCreateUpdate :registerInSelect="true" v-model="rent.typeRent" />
          </Select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Consultor" field="type" :formName="formName" :required="true" :disabled="disabled"
            url="/api/v1/human-resources/collaborator/select-all" :propsParams="{ isConsultant: true }"
            v-model="rent.collaborator" nameNewRegister="consultantCreateUpdate"
            :showNewRegister="hasRule('HumanResources')" titleNewRegister="Consultor" :widthNewRegister="800"
            :heightNewRegister="250">
            <ConsultantCreateUpdate :registerInSelect="true" v-model="rent.collaborator" />
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="8">
          <CustomerWithCreate :formName="formName" v-model="rent.customer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Contato" field="type" :formName="formName" :required="true" :disabled="!rent.customer.id"
            url="/api/v1/shared/contact/select-all" :propsParams="{ any: rent.customer.id }" v-model="rent.contact"
            nameNewRegister="contactCreateUpdate" :showNewRegister="hasRule('CRM')" titleNewRegister="Contato"
            :widthNewRegister="750" :heightNewRegister="250">
            <ContactCreateUpdate :registerInSelect="true" :customerSupplierId="rent.customer.id"
              v-model="rent.contact" />
          </Select>
        </b-col>
      </b-row>
      <br />
    </Molded>
    <div v-if="!id">
      <br />
      <Molded>
        <div class="div-period">
          <PeriodRentCreateUpdate msg="Como deseja Gerar o Período de Locação?" :showBtnSave="false"
            v-model="rent.periodRent" />
        </div>
      </Molded>
    </div>
    <div v-if="rent.id">
      <br />
      <Tab v-model="tabIndex" :initialTabs="tabsByModule">

        <div :slot="0">
          <PeriodRentList v-if="tabIndex == 0" :rentId="id" />
        </div>
        <div :slot="1">
          <ItemsRentList v-if="tabIndex == 1" :rent="rent" />
        </div>
        <div :slot="2">
          <PaymentRentList v-if="tabIndex == 2" :rentId="id" :showBtnLaunchFinance="rent.statusRental == 2" />
        </div>
        <div :slot="3">
          <AddressRentList v-if="tabIndex == 3" :rent="rent" />
        </div>
        <div :slot="4">
          <b-row>
            <b-col sm="12">
              <EditorHtml title="Observações da Locação" field="observationRental" :required="false" :maxLength="3000"
                v-model="rent.observationRental" />
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <EditorHtml title="Observações da OS" field="observationServiceOrder" :required="false" :maxLength="3000"
                v-model="rent.observationServiceOrder" />
            </b-col>
          </b-row>
        </div>
        <div :slot="5">
          <TeamList :rent="rent" />
        </div>
        <div :slot="6">
          <SignatureFileList :genericId="rent.id" />
        </div>
        <div :slot="7">
          <InvoiceList :rent="rent" />
        </div>
        <div :slot="8">
          <NfseList :rent="rent" />
        </div>
        <div :slot="9">
          <ItemsOsList :rent="rent" />
        </div>
        <div :slot="10">
          <MovimentList :rent="rent" />
        </div>
        <div :slot="11">
          <br>
          <FileManager :showInModal="true" source="rent" :genericId="id" />
        </div>
        <div :slot="12">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="2" xl="2">
              <span class="title">
                Data Cadastro <br>
                <i class="fa-solid fa-calendar"></i> {{ rent.registeredDate }}
              </span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="2" xl="2">
              <span class="title">
                Usuário Cadastro <br>
                <i class="fa-solid fa-user"></i> {{ rent.registeredUser }}
              </span>
            </b-col>
          </b-row>
          <div class="div-date-accepted">
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                <DateTime title="Data de Efetivação" field="dateAccepted" format="DD/MM/YYYY" type="date" placeholder
                  :formName="formName" :required="false" v-model="rent.dateAccepted" />
              </b-col>
            </b-row>
          </div>
        </div>
      </Tab>
    </div>
    <Modal title="Período" :width="900" :height="750" v-show="showModal('periodRentCreateUpdate')">
      <PeriodRentCreateUpdate :rentId="id" />
    </Modal>
    <Modal title="Pagamento" :width="700" :height="750" v-show="showModal('paymentRentCreateUpdate')">
      <PaymentRentCreateUpdate :rentId="id" />
    </Modal>
  </div>
</template>

<script>

import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Tab from "@nixweb/nixloc-ui/src/component/layout/Tab.vue";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import FileManager from "@nixweb/nixloc-ui/src/component/shared/file-manager/FileManager";
import SignatureFileList from '../../signature/signature-file/SignatureFileList.vue'

import RentTotalization from "./RentTotalizationRent.vue";
import TypeRentCreateUpdate from "../type-rent/TypeRentCreateUpdate.vue";
import ConsultantCreateUpdate from '../../human-resources/collaborator/ConsultantCreateUpdate.vue'
import CustomerWithCreate from "../shared/CustomerWithCreate.vue";
import ContactCreateUpdate from "../../shared/contact/ContactCreateUpdate.vue";
import GeneratePeriodRent from "../period-rental/GeneratePeriodRent.vue";
import PeriodRentList from "../period-rental/PeriodRentList.vue";
import PeriodRentCreateUpdate from "../period-rental/PeriodRentCreateUpdate.vue";
import ItemsRentList from "../items-rent/ItemsRentList.vue";
import PaymentRentCreateUpdate from "../payment-rent/PaymentRentCreateUpdate.vue";
import PaymentRentList from "../payment-rent/PaymentRentList.vue";
import DropdownOptionsRent from "../shared/DropdownOptionsRent.vue";
import InvoiceList from "../billing/InvoiceList.vue";
import NfseList from '../nfse/NfseList.vue'
import MovimentList from "../moviment/MovimentList.vue";
import AddressRentList from "../address-rent/AddressRentList.vue";
import TeamList from '../team/TeamList.vue'
import AutomationBuilder from '../../automation/AutomationBuilder.vue'
import ItemsOsList from '../maintenance/ItemsOsList.vue'

import Rent from "@/components/modules/rental/rent/Rent.js";

import { mapGetters, mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "RentCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    Badge,
    Select,
    RadioGroup,
    TextArea,
    Molded,
    Tab,
    InputText,
    Modal,
    DateTime,
    Button,
    EditorHtml,
    RentTotalization,
    TypeRentCreateUpdate,
    CustomerWithCreate,
    ConsultantCreateUpdate,
    ContactCreateUpdate,
    GeneratePeriodRent,
    PeriodRentList,
    PeriodRentCreateUpdate,
    ItemsRentList,
    DropdownOptionsRent,
    MovimentList,
    PaymentRentList,
    PaymentRentCreateUpdate,
    AddressRentList,
    InvoiceList, AutomationBuilder, NfseList, TeamList, FileManager, SignatureFileList, ItemsOsList
  },
  data() {
    return {
      id: this.$route.params.id,
      type: this.$route.params.type,
      formName: "rentCreateUpdate",
      rent: new Rent(),
      urlCreate: "/api/v1/rental/rent/create",
      urlUpdate: "/api/v1/rental/rent/update",
      urlGetById: "/api/v1/rental/rent/get-by-id",
      urlItemsMovimentTotalization: "/api/v1/stock/items-moviment/totalization",
      urlItemsRentTotalizationInvoice:
        "/api/v1/rental/items-rent/totalization-invoice",
      statusMovimentStock: {},
      totalProductForInvoice: 0,
      customerAndContact: {},
      tabIndex: 0,
      tabs: [
        { index: 0, title: "Periodo" },
        { index: 1, title: "Produto" },
        { index: 2, title: "Pagamento" },
        { index: 3, title: "Saída/Retorno" },
        { index: 4, title: "Observações" },
        { index: 5, title: "Equipe" },
        { index: 6, title: "Assinatura" },
        { index: 7, title: "Fatura" },
        { index: 8, title: "NFS-e" },
        { index: 9, title: "Manutenção" },
        { index: 10, title: "Movimentação" },
        { index: 11, title: "Anexo" },
        { index: 12, title: "Info" },
      ],
      rules: [
        {
          title: 'Gerar Faturamento',
          name: 'billingByRent',
          icon: 'fa-solid fa-file-invoice',
          frequency: {},
          amount: 0,
          dateStart: '',
          dateEnd: '',
          listActivity: []
        },
      ]
    };
  },
  created() {
    if (this.id) {
      this.getById();
      this.getStatusMovimentStock();
      this.getTotalProductForInvoice();
      this.tabIndex = 1;
    }
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "showModal", "isLoading"]),
    ...mapGetters("user", ["hasModule", "hasRule"]),
    statusRental() {
      if (this.rent.statusRental == 1)
        return { title: "Orçamento", type: "info" };

      if (this.rent.statusRental == 2)
        return { title: "Aprovada", type: "success" };

      if (this.rent.statusRental == 3)
        return { title: "Reprovada", type: "danger" };
    },
    tabsByModule() {
      let tabs = [];
      var isSignature = this.hasRule("Signature");
      var isBilling = this.hasRule("Billing");
      var isTaxDocument = this.hasRule("TaxDocument");
      var isOperational = this.hasRule("Operational");
      var isMaintenance = this.hasRule("Maintenance");

      this.tabs.forEach((tab) => {
        if (tab.title == "Assinatura" && isSignature) tabs.push(tab);
        if (tab.title == "Faturamento" && isBilling) tabs.push(tab);
        if (tab.title == "NFS-e" && isTaxDocument) tabs.push(tab);
        if (tab.title == "Manutenção" && isMaintenance) tabs.push(tab);
        if (tab.title == "Movimentação" && isOperational) tabs.push(tab);
        if (tab.title != "Faturamento" && tab.title != "Manutenção" && tab.title != "Movimentação" && tab.title != "NFS-e" && tab.title != "Assinatura")
          tabs.push(tab);
      });
      return tabs;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", [
      "addEvent",
      "removeLoading",
      "openModal",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      let params = { url: this.urlCreate, obj: this.rent };
      this.postApi(params).then((response) => {
        if (response.success)
          this.$router.replace({
            name: "rentUpdate",
            params: { id: response.content.id, type: response.content.type },
          });
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      let params = { url: this.urlUpdate, obj: this.rent };
      this.putApi(params).then((response) => {
        if (response.success) {
          let self = this;
          setTimeout(function () {
            self.addEvent({ name: "updateTotalizationRent" });
          }, 100);
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.rent.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
    getStatusMovimentStock() {
      let params = {
        url: this.urlItemsMovimentTotalization,
        obj: { rentId: this.id },
      };
      this.getApi(params).then((response) => {
        this.statusMovimentStock = response.content;
      });
    },
    getTotalProductForInvoice() {
      let params = {
        url: this.urlItemsRentTotalizationInvoice,
        obj: { rentId: this.id },
      };
      this.getApi(params).then((response) => {
        this.totalProductForInvoice = response.content;
      });
    },
    newRent() {
      this.$router.push({
        name: "rentSelectOption",
      });
      this.removeLoading(["btnRentCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        let name = event.name;

        if (name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (name == "periodRentCreateUpdate" || name == "periodRentUpdate") {
          this.openModal("periodRentCreateUpdate");
        }

        if (name == "paymentRentCreate" || name == "paymentRentUpdate") {
          this.openModal("paymentRentCreateUpdate");
        }

        if (name == "generatePeriodRent") this.rent.periodRent = event.data;

        if (
          name == "rentAccepted" ||
          name == "rentProcessed" ||
          name == "rentRejected"
        ) {
          this.getById();
          this.getTotalProductForInvoice();
          this.getStatusMovimentStock();
        }

        if (name == "addedProduct" || name == "removedItemRent") {
          this.getStatusMovimentStock();
          this.getTotalProductForInvoice();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-period {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.div-date-accepted {
  margin-top: 10px;
}
</style>
