export default class Job {
    constructor() {
        this.id = "";
        this.collaborator = { id: "", content: "" };
        this.description = "";
        this.dateStart = "";
        this.timeStart = "";
        this.dateEnd = "";
        this.timeEnd = "";
        this.dueDate = "";
        this.value = 0;
        this.statusRental = 0;
        this.payableReceivable = {};
        this.rentId = "";
    }
    update(data) {
        this.id = data.id;

        if (data.collaborator)
            this.collaborator = { id: data.collaborator.id, content: data.collaborator.person.companyName + " (" + data.collaborator.person.tradeName + ")" };

        this.description = data.description;
        this.dateStart = data.dateStart;
        this.timeStart = data.timeStart;
        this.dateEnd = data.dateEnd;
        this.timeEnd = data.timeEnd;
        this.dueDate = data.dueDate;
        this.value = data.value;
        this.payableReceivable = data.payableReceivable;
    }
}