<template>
    <div class="div-main">
        <div v-if="loading">
            <span>Aguarde, estamos clonando, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <div v-if="!success">
                <InputText title="Identificação" field="identification" :maxLength="100" v-model="identification" />
                <CheckboxGroup title="Marque as opções que serão clonadas (se houver)" :initialValue="initialValue"
                    :stacked="true" :options="[
                        { text: 'Produto(s) Operacional', value: 'onlyStock' },
                        { text: 'Pagamento', value: 'payment' },
                        { text: 'Saída/Retorno', value: 'addressRent' },
                        { text: 'Equipe', value: 'team' },
                    ]" v-model="optionsSelected" />
                <div class="text-center">
                    <Button _key="btnClone" title="Clonar" :disabled="identification.length > 100"
                        classIcon="fa-solid fa-clone" backGroundColor="#009183" color="white" size="medium"
                        :clicked="clone" />
                </div>
            </div>
            <div v-else>
                <div class="div-success text-center">
                    <i class="fa-light fa-circle-check icon-success"></i>
                    <div class="msg-success"> Locação clonada Nº{{ rentCloned.number }}
                    </div>
                    <div>
                        <Button _key="btnBack" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                            :clicked="back" />

                        <Button _key="btnNavegate" title="Ir para locação" classIcon="fa-solid fa-link" size="medium"
                            :clicked="navegateTo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations } from "vuex";

export default {
    name: "CloneRent",
    props: {
        rent: Object
    },
    components: {
        Button,
        CheckboxGroup,
        InputText,
        Loading
    },
    data() {
        return {
            urlClone: "/api/v1/rental/rent/clone",
            initialValue: ['payment', 'observation', 'addressRent', 'team'],
            optionsSelected: [],
            identification: "",
            success: false,
            rentCloned: {},
            loading: false,
        }
    },
    mounted() {
        this.identification = this.rent.identification;
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["removeLoading", "hideModal"]),
        clone() {
            this.loading = true;
            let params = {
                url: this.urlClone, obj: {
                    rentId: this.rent.id,
                    identification: this.identification,
                    options: this.optionsSelected
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    let self = this;
                    setTimeout(function () {
                        self.success = true;
                        self.loading = false;
                        self.rentCloned = response.content;
                        self.removeLoading(["btnClone"]);
                    }, 2000);

                }
            });
        },
        back() {
            this.success = false;
            this.removeLoading(["btnBack"]);
        },
        navegateTo() {
            this.hideModal();
            this.removeLoading(["btnNavegate"]);
            this.$router.push({
                name: "rentUpdate",
                params: { id: this.rentCloned.id },
            });
        },
    }
}
</script>
<style scoped>
.div-main {
    min-height: 200px;
}

.msg-success {
    font-size: 18px;
    margin: 10px;
}

.icon-success {
    color: #38a169;
    font-size: 30px;
}

.div-success {
    padding-top: 30px;
    height: 200px;
}

.title-navegate {
    color: rgb(2, 2, 63);
    cursor: pointer;
    font-size: 16px;
}

.title-navegate:hover {
    text-decoration: underline;
}
</style>