<template>
  <div>
    <Alert type="warning">
      <span>
        Atenção, alguns processos serão executados, esta ação é irreversível...
      </span>
    </Alert>
    <CheckboxGroup title="Marque as opções (se houver)" :stacked="true" :options="[
      { text: 'Excluir Contas a Receber', value: 'removeReceive' },
      { text: 'Excluir Contas a Pagar', value: 'removePay' },
    ]" v-model="optionsSelected" />
    <div class="text-center">
      <Button _key="btnRentProgress" title="Orçamento" type="info" size="medium" :clicked="update" />
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import { mapMutations, mapActions } from "vuex";

export default {
  name: "RentProgress",
  props: {
    rentId: String,
  },
  components: {
    Button,
    CheckboxGroup,
    Alert
  },
  data() {
    return { urlUpdate: "/api/v1/rental/rent/progress", optionsSelected: [], };
  },
  methods: {
    ...mapActions("generic", ["putApi"]),
    ...mapMutations("generic", ["addEvent", "hideModal", "removeLoading"]),
    update() {
      let params = { url: this.urlUpdate, obj: { rentId: this.rentId, options: this.optionsSelected } };
      this.putApi(params).then((response) => {
        if (response.success) {
          this.addEvent({ name: "rentProcessed" });
          this.hideModal();
        }
        this.removeLoading(["btnRentProgress"]);
      });
    },
  },
};
</script>