<template>
    <div>
        <div>
            <Loading type="line" :center="false" v-show="loading" />
            <b-row>
                <b-col sm="12">
                    <TableTotalRecords :totalRecords="content.totalRecords" />
                </b-col>
            </b-row>
        </div>
        <div class="table-items-nfse">
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th>
                            <span class="title-header">PRODUTO</span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">QUANTIDADE</span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">ST ORÇAMENTO</span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">INÍCIO</span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">RETORNO </span>
                        </th>
                        <th class="text-center">
                            <span class="title-header">ST MANUTENÇÃO</span>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-for="item in content.data">
                    <tr>
                        <td>
                            <i v-if="item.barCode" class="fas fa-box"></i>
                            <i v-else class="fas fa-toolbox"></i>
                            {{ item.productFullName }}
                            <span class="title" v-if="item.barCode">
                                - Nº Patrimônio {{ item.barCode }}
                            </span>
                        </td>
                        <td class="text-center">
                            {{ item.quantity }}
                        </td>
                        <td class="text-center">
                            <span class="badge badge-info" v-if="item.statusItemName == 'Orçamento'">Orçamento</span>
                            <span class="badge badge-success" v-if="item.statusItemName == 'Aprovada'">Aprovada</span>
                            <span class="badge badge-danger" v-if="item.statusItemName == 'Reprovada'">Reprovada</span>
                            <span class="inactivate badge badge-danger" v-if="item.inactivate">Inativo no Estoque</span>
                        </td>
                        <td class="text-center">
                            <span class="title"> {{ item.dateStartStr }} </span>
                        </td>
                        <td class="text-center">
                            <span class="title"> {{ item.dateEndStrTitle }} </span>
                        </td>
                        <td class="text-center">
                            <div v-if="item.returned">
                                <span class="badge badge-success">Retornado</span>
                            </div>
                            <div v-else>
                                <span class="badge badge-warning">Pendente</span>
                            </div>
                        </td>
                        <td class="text-center">
                            <Button :_key="item.id" type="edit" title="Ver OS" classIcon="fa-light fa-wrench"
                                size="small" :clicked="navegateTo" :params="item" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-show="content.totalRecords == 0">
                    <tr>
                        <td colspan="8">
                            <span class="title">Nenhum registro encontrado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";

import { mapMutations, mapActions } from "vuex";

export default {
    name: "ItemsOsList",
    props: ["rent"],
    components: {
        Loading, TableTotalRecords, Button
    },
    data() {
        return {
            urlGetApi: "/api/v1/maintenance/items-os/get-all-by-rentId",
            content: {
                data: [],
                totalRecords: 0,
            },
        }
    },
    mounted() {
        this.getAll();
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),
        ...mapActions("generic", ["getApi"]),
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj: {
                    any: this.rent.id,
                },
            };
            this.getApi(params).then((response) => {
                this.content.data = response.content.data;
                this.content.totalRecords = response.content.totalRecords;
                this.loading = false;
            });
        },
        navegateTo(item) {
            this.$router.push({
                name: "orderServiceUpdate",
                params: { id: item.orderServiceId }
            });
            this.removeLoading([item.id]);
        },
    }
}

</script>

<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-dropdown {
    overflow: visible;
}

.td-margin {
    margin-top: 3px;
}

.inactivate {
    margin-left: 10px;
}
</style>
